import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { SliderOwnProps } from '@mui/material/Slider/Slider';

import { LedDisplay } from '@lib/robo/modules';
import { ModuleId } from '@lib/robo/types';
import Slider from '@webapp/components/ui/sliders/slider';
import { LedPixelMatrixComponent } from '@webapp/components/blocks/component/led-pixel-matrix-component';
import {
  convertToTwoDimensionalArray,
  createInitialMatrixState,
  rotateOneDimensionalMatrix,
} from '@webapp/components/blocks/widgets/led-pixel-display-widget/utils';
import { ClearIcon, CodeRotateIcon, PlusIcon, TimeIcon } from '@webapp/components/icons';
import { colors } from '@themes/config/theme-colors';
import { LedPixelMatrixComponentPreview } from '@webapp/components/blocks/component/led-pixel-matrix-component-preview';
import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';
import { AbortablePromise } from '@lib/utils/abortable-promise';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import IconWithText from '@webapp/components/ui/icon-with-text';
import RoundIconButton from '@webapp/components/ui/buttons/round-icon-button';

const widgetConfig = {
  maximumDrawingsCount: 10,
  maxAsInfinite: true,
  minDuration: 1,
  maxDuration: 11,
  updateWidgetDataDelay: 300,
} as const;

const CodeDrawWidget: ExecutableActionWidgetComponent<DrawWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData: _updateWidgetData } = useCodeEditor();

  const updateWidgetData: typeof _updateWidgetData<DrawWidgetData> = useMemo(
    () =>
      debounce((id, data) => {
        _updateWidgetData<DrawWidgetData>(id, data);
      }, widgetConfig.updateWidgetDataDelay), // Adjust the debounce delay as needed
    [id]
  );

  const widget = getWidgetById<DrawWidgetData>(id);
  const widgetData = widget?.data;

  const size = LedDisplay.DisplaySize;
  const [duration, setDuration] = useState<number>(widgetData?.duration ?? CodeDrawWidget.initialData.duration);
  const [isDurationInfinite, setIsDurationInfinite] = useState<boolean>(
    widgetData?.isDurationInfinite ?? CodeDrawWidget.initialData.isDurationInfinite
  );
  const [drawings, setDrawings] = useState<Array<Array<boolean>>>(
    widgetData?.drawings ?? CodeDrawWidget.initialData.drawings
  );
  const [activeDrawingIndex, setActiveDrawingIndex] = useState<number>(
    widgetData?.activeDrawingIndex ?? CodeDrawWidget.initialData.activeDrawingIndex
  );
  const [rotation, setRotation] = useState<number>(widgetData?.rotation ?? CodeDrawWidget.initialData.rotation);
  const { model: roboModel } = useRobo();

  const moduleId = widgetData?.moduleIds[0] as ModuleId;
  const MODULE = roboModel?.modules.ledDisplays[moduleId];

  const handleClear = () => {
    setDrawings(prev => {
      return [
        ...prev.slice(0, activeDrawingIndex),
        createInitialMatrixState(size),
        ...prev.slice(activeDrawingIndex + 1),
      ];
    });
    sendMatrixToLedDisplay(createInitialMatrixState(size), rotation);
  };

  const handleRotate = () => {
    const nextRotation = (rotation + 90) % 360;
    setRotation(nextRotation);
    sendMatrixToLedDisplay(drawings[activeDrawingIndex], nextRotation);
    updateWidgetData(id, { rotation: nextRotation });
  };

  const handleAdd = () => {
    const nextDrawings = [...drawings, createInitialMatrixState(size)];
    const nextIndex = nextDrawings.length - 1;
    setDrawings(nextDrawings);
    setActiveDrawingIndex(nextIndex);
    sendMatrixToLedDisplay(nextDrawings[nextIndex], rotation);
    updateWidgetData(id, { activeDrawingIndex: nextIndex });
  };

  const handleDurationChange: SliderOwnProps['onChange'] = (_, newValue) => {
    if (typeof newValue !== 'number') {
      return;
    }

    let isDurationInfinite;
    if (newValue >= widgetConfig.maxDuration && widgetConfig.maxAsInfinite) {
      isDurationInfinite = true;
    } else {
      isDurationInfinite = false;
    }

    setDuration(newValue);
    setIsDurationInfinite(isDurationInfinite);
    updateWidgetData(id, { duration: newValue, isDurationInfinite });
  };

  const createRemoveHandler = (index: number) => () => {
    const nextIndex = index - 1 >= 0 ? index - 1 : 0;
    setActiveDrawingIndex(nextIndex);
    sendMatrixToLedDisplay(drawings[nextIndex], rotation);
    setDrawings([...drawings.slice(0, index), ...drawings.slice(index + 1)]);
  };

  const sendMatrixToLedDisplay = (matrix: Array<boolean>, rotation: number) => {
    if (!MODULE) {
      return;
    }
    const twoDimMatrix = convertToTwoDimensionalArray(rotateOneDimensionalMatrix(matrix, size, rotation), size);
    MODULE.setImage(twoDimMatrix);
  };

  // update widget data in the store on drawings change
  useEffect(() => {
    updateWidgetData(id, { drawings });
  }, [id, drawings]);

  // send matrix to led display on mount
  useEffect(() => {
    sendMatrixToLedDisplay(drawings[activeDrawingIndex], rotation);

    return () => {
      sendMatrixToLedDisplay(createInitialMatrixState(size), 0);
    };
  }, []);

  const canAddMoreDrawings = drawings.length < widgetConfig.maximumDrawingsCount;

  return (
    <Box
      sx={{
        paddingTop: '10px',
        paddingLeft: '10px',
        width: '400px',
      }}
    >
      <Grid container spacing={0}>
        <Grid
          item
          xs={1}
          sx={{
            paddingBottom: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconWithText
            sx={{
              marginBottom: '42px',
            }}
            icon={<TimeIcon sx={{ fontSize: 'inherit', width: 30, height: 30 }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Time
              </Typography>
            }
          />
          <Slider
            value={duration}
            valueLabelDisplay="on"
            maxAsInfinite={widgetConfig.maxAsInfinite}
            minAsInfinite={false}
            max={widgetConfig.maxDuration}
            min={widgetConfig.minDuration}
            step={1}
            onChange={handleDurationChange}
            orientation="vertical"
            sx={{ height: '100%', width: '30px' }}
            mainColor="#FEC84B"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="default"
          />
        </Grid>

        <Grid item xs={9} sx={{ paddingLeft: '25px' }}>
          <Grid item xs={12}>
            <LedPixelMatrixComponent
              size={size}
              matrix={drawings[activeDrawingIndex]}
              theming="yellow"
              sx={{
                // this fix is needed for Safari, oherwise it calculates height in this
                // particular layout not properly
                height: 'initial',
              }}
              onMatrixChanged={matrix => {
                setDrawings(prev => {
                  return [...prev.slice(0, activeDrawingIndex), matrix, ...prev.slice(activeDrawingIndex + 1)];
                });
              }}
              onDragEnd={matrix => {
                sendMatrixToLedDisplay(matrix, rotation);
              }}
            />
          </Grid>
          {/* PREVIEW */}
          <Grid
            item
            xs={12}
            sx={{
              overflowY: 'auto',
              padding: '16px 0px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '7px',
                alignItems: 'center',
              }}
            >
              <Tooltip title={canAddMoreDrawings ? '' : `Maximum is ${widgetConfig.maximumDrawingsCount} drawings`}>
                <span>
                  <IconButton
                    onClick={handleAdd}
                    disabled={!canAddMoreDrawings}
                    sx={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '5px',
                      background: colors.black['050'],
                      marginRight: '3px',
                    }}
                  >
                    <PlusIcon />
                  </IconButton>
                </span>
              </Tooltip>
              {drawings.map((matrix, index) => (
                <LedPixelMatrixComponentPreview
                  key={index}
                  size={size}
                  theming="yellow"
                  matrix={matrix}
                  selected={activeDrawingIndex === index}
                  onClick={() => {
                    setActiveDrawingIndex(index);
                    sendMatrixToLedDisplay(drawings[index], rotation);
                    updateWidgetData(id, { activeDrawingIndex: index });
                  }}
                  onRemove={drawings.length === 1 ? undefined : createRemoveHandler(index)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <RoundIconButton
            icon={<CodeRotateIcon sx={{ width: 25, height: 25 }} />}
            onClick={handleRotate}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Rotate
              </Typography>
            }
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
          <RoundIconButton
            icon={<ClearIcon sx={{ width: 25, height: 25 }} />}
            onClick={handleClear}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Clear
              </Typography>
            }
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

CodeDrawWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, async (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const widgetData = widget.data;
    const moduleId = widgetData.moduleIds[0] as ModuleId;
    const { drawings, duration, isDurationInfinite, activeDrawingIndex, rotation } = widgetData;

    if (!moduleId || !roboModel) {
      throw new Error('Module or RoboModel not found');
    }

    if (drawings.length === 0) {
      throw new Error('No drawings available');
    }

    const LED_DISPLAY = roboModel.modules.ledDisplays[moduleId];
    const matrix = convertToTwoDimensionalArray(
      rotateOneDimensionalMatrix(drawings[activeDrawingIndex], LedDisplay.DisplaySize, rotation),
      LedDisplay.DisplaySize
    );

    const time = isDurationInfinite ? LedDisplay.InfiniteTime : duration * 1000;
    LED_DISPLAY.setImageAction(matrix, 0, time, ({ isError }) => {
      if (isError) {
        reject(new Error('Error setting image action'));
      } else {
        resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
      }
    });
  });
};

type DrawWidgetData = {
  drawings: Array<Array<boolean>>;
  duration: number;
  isDurationInfinite: boolean;
  activeDrawingIndex: number;
  rotation: number;
};

CodeDrawWidget.initialData = {
  drawings: [createInitialMatrixState(LedDisplay.DisplaySize)],
  duration: 0,
  activeDrawingIndex: 0,
  isDurationInfinite: false,
  rotation: 0,
};

export default CodeDrawWidget;
