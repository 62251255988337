import { BaseModule, IRoboStore, onActionDoneType } from './base-module';
import { RoboClient } from '@lib/robo/robo-client';
import { ModulesCollectionTypes, HandlerType } from '@lib/robo/types';

export enum DistanceCondition {
  LessThan = 'lessThan',
  GreaterThan = 'greaterThan',
}

export class Ultrasonic extends BaseModule<typeof Ultrasonic> {
  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.Ultrasonic, store);

    this.client.registerHandler(
      HandlerType.OnProximity,
      ({ data }) => {
        const distance = Ultrasonic.parsePayload(data);

        const currentData = this.getDataState();
        if (!currentData.sensorData || currentData.sensorData.distance === distance) {
          return;
        }

        this.updateDataState({
          sensorData: {
            distance,
          },
        });
      },
      id
    );
  }

  /**
   * Create a trigger for the ultrasonic sensor
   * @param condition - The condition to check the distance against
   * @param distance - The distance to check against
   * @param onActionDone - The action to perform when the condition is met
   * @returns The trigger id
   */
  createTrigger(condition: DistanceCondition, distance: number, onActionDone: onActionDoneType) {
    const triggerId = this.generateActionOrTriggerId();
    const triggerCondition = condition === DistanceCondition.LessThan ? 0 : 1;
    const desiredDistance = distance;

    this.subscribeToResponse(triggerId, onActionDone);

    this.client.setProximityTrigger(triggerId, this.index, triggerCondition, desiredDistance);

    return { triggerId };
  }

  /**
   * Parse the payload from the ultrasonic sensor
   * @param data - The data to parse
   * @returns The distance
   */
  static parsePayload(data: Uint8Array) {
    return data[0];
  }

  /**
   * Get the initial data state
   * @returns The initial data state
   */
  static initialDataState() {
    return {
      sensorData: {
        distance: 0,
      },
    };
  }
}
