import Layout from '@layouts/layout';
import * as Sentry from '@sentry/gatsby';

import wrapWithProvider from '@store/utils/wrap-with-provider';
import packageJson from './package.json';

require('@fontsource/raleway/400.css');
require('@fontsource/raleway/600.css');
require('@fontsource/raleway/700.css');
require('@fontsource/raleway/900.css');

Sentry.init({
  dsn: 'https://f076e9cd064164171a753b3f9227f039@o4506825760374784.ingest.sentry.io/4506825763913728',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  normalizeDepth: 4,
  environment: process.env.NODE_ENV ?? 'development',
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: packageJson.version,
  enabled: location.hostname !== 'localhost' && location.hostname !== '127.0.0.1',
});

export const wrapRootElement = wrapWithProvider;

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
