import { gql } from '@lib/store/strapi-graphql-query';

export const ME_QUERY = gql`
  query Me {
    me {
      id
      username
      role {
        id
        name
        type
        description
      }
    }
  }
`;
