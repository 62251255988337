import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Joystick } from 'react-joystick-component';

import { Box } from '@mui/material';

import { EditorContext } from '@webapp/components/hoc/with-editor';
import StyledBox from '@webapp/components/blocks/component/styled-box';
import { WidgetSettingsIcon } from '@webapp/components/icons';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useEditor } from '@webapp/hooks/use-editor-hook';

import SettingsModal from './joystick-components/joystick-settings-modal';
import SettingsButton from './joystick-components/settings-button';
import JoystickBase from './joystick-components/joystick-base';
import JoystickHandle from './joystick-components/joystick-handle';

import { MOTOR_PLACEMENT, ROTATION_DIRECTIONS } from '@lib/robo/modules/motor';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';
import { LiveWidgetProps } from '@webapp/types/types';
import { EditorType } from '@webapp/store/types';
import { ModuleId } from '@lib/robo/types';
import { IJoystickUpdateEvent } from 'react-joystick-component/src/Joystick';
import { clamp } from '@lib/utils/math';

export const ALIGNMENT = {
  left: 'left',
  right: 'right',
  none: 'none',
} as const;

type AlignmentType = (typeof ALIGNMENT)[keyof typeof ALIGNMENT];
type MotorsConfig = Record<ModuleId, { alignment: AlignmentType }>;

const JoystickWidget = ({ data, disabled }: LiveWidgetProps<{ motorsConfig: MotorsConfig; isReversed: boolean }>) => {
  const { id } = data;
  const { updateWidgetData } = useEditor(EditorType.Live);

  const { isPlaying } = useContext(EditorContext);
  const { model, store: roboStore, connected } = useRobo();

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const store = roboStore?.model;
  const connectedMotorIds = useMemo(() => Object.keys(store?.motors || {}) as ModuleId[], [store?.motors]);
  const connectedMotorsState = store?.motors;
  const connectedMotorsModules = model?.modules?.motors;

  // caclulate motors alignment dynamically
  useEffect(() => {
    const motorsConfig: MotorsConfig = connectedMotorIds.reduce((acc, motorId, index) => {
      acc[motorId] = { alignment: index % 2 === 0 ? ALIGNMENT.right : ALIGNMENT.left };
      return acc;
    }, {} as MotorsConfig);

    updateWidgetData(id, { motorsConfig });
  }, [id, connectedMotorIds]);

  /**
   * Handles the change of the motor alignment.
   * @param {string} motorId - The id of the motor to change the alignment of.
   * @param {string} alignment - The new alignment of the motor.
   */
  const handleMotorAlignmentChange = (motorId: ModuleId, alignment: AlignmentType) => {
    const newMotorsConfig = {
      ...data.motorsConfig,
      [motorId]: {
        ...data.motorsConfig[motorId],
        alignment,
      },
    };

    updateWidgetData(id, { motorsConfig: newMotorsConfig });
  };

  /**
   * Handles the change of the "isReversed" property.
   * @param {boolean} isReversed - The new value of the "isReversed" property.
   */
  const handleIsReversedChange = (isReversed: boolean) => {
    handleWidgetDataChange('isReversed', isReversed);
  };

  /**
   * Handles the change of the widget data.
   * @param {string} key - The key of the property to change.
   * @param {any} value - The new value of the property.
   */
  const handleWidgetDataChange = (key: string, value: unknown) => {
    updateWidgetData(id, { [key]: value });
  };

  /**
   * Handles the probe of a motor.
   * @param {string} motorId - The id of the motor to probe.
   */
  const handleMotorProbe = (motorId: ModuleId) => {
    if (!connectedMotorsModules?.[motorId]) {
      return;
    }

    const MOTOR = connectedMotorsModules[motorId];
    const probeAngle = 120;

    const configuredMotorAlignment = data.motorsConfig[motorId].alignment;
    const isReversed = data.isReversed;

    let rotationDirection = ROTATION_DIRECTIONS.cw;

    switch (configuredMotorAlignment) {
      case ALIGNMENT.left:
        rotationDirection = isReversed ? ROTATION_DIRECTIONS.cw : ROTATION_DIRECTIONS.ccw;
        break;
      case ALIGNMENT.right:
        rotationDirection = isReversed ? ROTATION_DIRECTIONS.ccw : ROTATION_DIRECTIONS.cw;
        break;
      case ALIGNMENT.none:
        rotationDirection = ROTATION_DIRECTIONS.cw;
        break;
    }

    MOTOR.angle(probeAngle, rotationDirection);
  };

  /**
   * Handles the start of interaction with the settings.
   * @param {Event} event - The event object.
   */
  const handleSettingsInteractionStart = (
    event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    if (!connected || disabled) return;

    // prevent the editor from dragging the widget
    event.stopPropagation();
  };

  /**
   * Handles the click event for the settings button.
   * @param {Event} event - The click event.
   */
  const handleSettingsClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!connected || disabled) return;

    event.stopPropagation();

    setShowSettingsModal(true);
  };

  /**
   * Handles the movement of the joystick.
   *
   * @param {number} value - The value representing the movement of the joystick.
   */

  const handleJoystickMove = (value: IJoystickUpdateEvent) => {
    const x = value.x ?? 0;
    const y = value.y ?? 0;

    let L = x + y;
    let R = x - y;

    R *= -1;

    // Clamp the values between -1 and 1
    L = clamp(L, -1, 1);
    R = clamp(R, -1, 1);

    // Reverse motor direction if needed
    const isReversed = data.isReversed;

    connectedMotorIds.forEach(motorId => {
      const MOTOR = connectedMotorsModules?.[motorId];
      const configuredMotorAlignment = data.motorsConfig[motorId].alignment;

      let speed = 0;
      let motorPlacement = MOTOR_PLACEMENT.right;

      if (configuredMotorAlignment === ALIGNMENT.left) {
        speed = Math.round(L * 100);
        motorPlacement = isReversed ? MOTOR_PLACEMENT.right : MOTOR_PLACEMENT.left;
      } else if (configuredMotorAlignment === ALIGNMENT.right) {
        speed = Math.round(R * 100);
        motorPlacement = isReversed ? MOTOR_PLACEMENT.left : MOTOR_PLACEMENT.right;
      }

      if (speed === 0) {
        // stop motor immediately
        MOTOR?.setSpeedDebounced.cancel();
        MOTOR?.setSpeed(0);
      } else {
        MOTOR?.setSpeedDebounced(
          speed,
          motorPlacement === MOTOR_PLACEMENT.right ? ROTATION_DIRECTIONS.cw : ROTATION_DIRECTIONS.ccw
        );
      }
    });
  };

  return (
    <>
      <StyledBox color={disabled ? WidgetColors.disabledBackground : '#156BFB'} sx={{ position: 'relative' }}>
        <Joystick
          size={155}
          sticky={false}
          baseImage={`data:image/svg+xml;base64,${btoa(JoystickBase)}`}
          stickImage={`data:image/svg+xml;base64,${btoa(JoystickHandle)}`}
          stickSize={80}
          throttle={50}
          stop={handleJoystickMove}
          move={handleJoystickMove}
          disabled={!isPlaying || disabled}
        />

        <SettingsButton
          onClick={handleSettingsClick}
          onMouseDown={handleSettingsInteractionStart}
          onTouchStart={handleSettingsInteractionStart}
        >
          <WidgetSettingsIcon />
        </SettingsButton>
        {(!isPlaying || disabled) && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1001,
            }}
          />
        )}
      </StyledBox>

      {Object.keys(data.motorsConfig).length === connectedMotorIds.length && (
        <SettingsModal
          open={showSettingsModal}
          dense
          onClose={() => setShowSettingsModal(false)}
          showCancelButton={false}
          showSubmitButton={false}
          showCloseButton={false}
          isReversed={data.isReversed}
          setIsReversed={handleIsReversedChange}
          setMotorAlignment={handleMotorAlignmentChange}
          motorsState={connectedMotorsState}
          motorsConfig={data.motorsConfig}
          probeMotor={handleMotorProbe}
        />
      )}
    </>
  );
};
//isReversed, setIsReversed, setMotorAlignment, motorsState, motorsConfig, probeMotor
JoystickWidget.initialProps = {
  width: 2,
  height: 2,
};

JoystickWidget.initialData = {
  isReversed: false,
  motorsConfig: {},
} as MotorsConfig;

export default JoystickWidget;
