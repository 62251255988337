import { createApi } from '@reduxjs/toolkit/query/react';
import protectedQuery from '@store/utils/protected-query.graphql';

import {
  USER_LOGIN_MUTATION,
  USER_TEST_LOGIN_MUTATION,
  FORGOT_PASSWORD_MUTATION,
  RESET_PASSWORD_MUTATION,
  CHANGE_PASSWORD_MUTATION,
} from '@store/queries/user';
import { ME_QUERY } from '@store/queries/me';
import { Maybe, UsersPermissionsMe } from '@store/gql/graphql';

export const userApiGraphql = createApi({
  reducerPath: 'userApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        document: USER_LOGIN_MUTATION,
        variables: credentials,
      }),
    }),
    testLogin: builder.mutation({
      query: credentials => ({
        document: USER_TEST_LOGIN_MUTATION,
        variables: credentials,
      }),
      transformResponse: (response: any) => {
        if (response.testLogin) {
          return {
            login: response.testLogin,
          };
        }
        return response;
      },
    }),
    me: builder.query<{ me: Maybe<UsersPermissionsMe> }, void>({
      query: () => {
        return {
          document: ME_QUERY,
        };
      },
    }),

    forgotPassword: builder.mutation({
      query: email => ({
        document: FORGOT_PASSWORD_MUTATION,
        variables: { email },
      }),
    }),

    resetPassword: builder.mutation({
      query: ({ password, passwordConfirmation, code }) => ({
        document: RESET_PASSWORD_MUTATION,
        variables: { password, passwordConfirmation, code },
      }),
    }),

    changePassword: builder.mutation({
      query: ({ currentPassword, password, passwordConfirmation }) => ({
        document: CHANGE_PASSWORD_MUTATION,
        variables: { currentPassword, password, passwordConfirmation },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useMeQuery,
  useLazyMeQuery,
  useTestLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = userApiGraphql;
