import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../configureStore';
import { UserRole } from '@store/types/common';

type AuthState = {
  isLoggedIn: boolean;
  user: User | null;
  jwt: string | null;
};

type User = {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
  role?: UserRole;
  isTest?: boolean;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    jwt: null,
    isLoggedIn: false,
  } as AuthState,

  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, jwt },
      }: PayloadAction<{
        user: User;
        jwt: string;
      }>
    ) => {
      state.user = user;
      state.jwt = jwt;
      state.isLoggedIn = true;
    },
    setRole: (state, { payload: { role } }: PayloadAction<{ role: UserRole }>) => {
      if (state.user) {
        (state.user as User).role = role;
      }
    },
    clearCredentials: state => {
      state.user = null;
      state.jwt = null;
      state.isLoggedIn = false;
    },
  },
});

export const { setCredentials, clearCredentials, setRole } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState): User | object | null => state.auth.user;
export const selectToken = (state: RootState): string | null => state.auth.jwt;
export const selectAuth = (state: RootState): AuthState => state.auth;
